const defaultConfig = {
    env: 'development', // 本地开发环境
    // 接口域名配置
    domain: {
        httpUrl:'https://api.expert.ustep.cn',
        wssUrl:'wss://api.expert.ustep.cn/ws',
        // httpUrl:'http://10.220.22.141:443',
        // wssUrl:'ws://10.220.22.141:443/ws',
    },
};
const config = {
    test:{
        env: 'test',
        // 域名配置
        domain: {
            httpUrl:'https://api.expert.ustep.cn',
            wssUrl:'wss://api.expert.ustep.cn/ws',
        }
    },
    privatization:{
        env: 'privatization',
        // 域名配置
        domain: {
            httpUrl:'https://10.21.102.105:443',  //张家茆矿业
            wssUrl:'wss://10.21.102.105:443/ws',
            // httpUrl:'http://10.10.52.71:8080', //黄陵矿业
            // wssUrl:'ws://10.10.52.71:8080/ws',
        }
    },
    production:{
        env: 'production',
        domain:{
            httpUrl:' https://api.lingyun.ustep.cn',
            wssUrl:'wss://api.lingyun.ustep.cn/ws',
        }
    }
}

// 根据环境变量 配置环境参数, 默认是本地开发环境
const env = process.env.NODE_ENV;
let envConfig = defaultConfig;
switch (env) {
    case 'test':
        envConfig = config.test;
        break;
    case 'privatization':
        envConfig = config.privatization;
        break;
    case 'production':
        envConfig = config.production;
        break;
}
export const  appConfig = envConfig




